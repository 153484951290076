<template>
  <div class="articledetail">
    <van-cell size="large" v-if="article">
      <template #title>
        <div class="dis-flex">
          <span class="font-20 font-blue font-comfortable">{{article.title}}</span>
        </div>
      </template>
      <template #label>
        <div class="mg-t-25">
          <span class="font-12 font-gray">{{article.created_at}}</span>
        </div>
        <div class="mg-t-15"></div>
      </template>
    </van-cell>
    <div class="content" v-if="article">
      <div v-html="article.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      article: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "ArticleDetail") {
      this.id = to.params.id;
      this.getarticle();
    }
    next();
  },
  methods: {
    async getarticle() {
      const resp = await this.$api.get("getarticle", {
        id: this.id,
      });
      if (resp.success) {
        this.article = resp.data.article;
      }
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    await this.getarticle();
  },
};
</script>
